<template>
  <div class="row">
<!--    <div class="col-lg-12">-->
<!--      <div class="row">-->
<!--        <div class="col-lg-8 col-sm-8 filters">-->
<!--          <div class="filters__search">-->
<!--            <div class="input-group input-group-sm">-->
<!--              <span class="input-group-addon">-->
<!--                <i class="fa fa-search"></i>-->
<!--              </span>-->
<!--              <input-->
<!--                id="search"-->
<!--                name="search"-->
<!--                :placeholder="$t('aps.search')"-->
<!--                class="form-control filters__input"-->
<!--                v-model="filters.query"-->
<!--                @input="disableStatMode()"-->
<!--              />-->
<!--              <span class="clear-input text-muted" v-if="filters.query != ''" @click="filters.query = ''">-->
<!--                <i class="fa fa-remove"></i>-->
<!--              </span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row mb-1">-->
<!--        <div class="col-lg-4 col-md-12">-->
<!--          <div class="input-group input-group-sm filters__location">-->
<!--            <span class="input-group-addon filters__field-icon">-->
<!--              <i class="fa fa-building-o" :class="{ 'text-primary': filters.company !== 'All companys' }"></i>-->
<!--            </span>-->
<!--            <select-->
<!--              @change="changeCompany"-->
<!--              @input="disableStatMode()"-->
<!--              class="form-control form-control-sm filters__field"-->
<!--              v-model="filters.company"-->
<!--            >-->
<!--              <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>-->
<!--              <option v-for="company in companys" :value="company" :key="company.name">-->
<!--                {{ company.name }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-lg-4 col-md-12 d-flex">-->
<!--          <div class="input-group input-group-sm filters__location input-group-for-location-select-in-statwlans&#45;&#45;filters" v-if="filters.company === 'All companys'">-->
<!--&lt;!&ndash;            <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--&lt;!&ndash;              <i class="fa fa-map-marker"></i>&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--&lt;!&ndash;            <select&ndash;&gt;-->
<!--&lt;!&ndash;              @change="refreshWlansRating"&ndash;&gt;-->
<!--&lt;!&ndash;              class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--&lt;!&ndash;              v-model="filters.location"&ndash;&gt;-->
<!--&lt;!&ndash;              @input="disableStatMode()"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>&ndash;&gt;-->
<!--&lt;!&ndash;              <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--&lt;!&ndash;                {{ location.name }}&ndash;&gt;-->
<!--&lt;!&ndash;              </option>&ndash;&gt;-->
<!--&lt;!&ndash;            </select>&ndash;&gt;-->


<!--                                                       <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-map-marker"-->
<!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
<!--                </span>-->
<!--                  <SelectComponentV2-->
<!--                    v-if="showLocationFilter"-->
<!--                    no-wrap-selected-option-->
<!--                    class="select-location-in-statwlans&#45;&#45;filters"-->
<!--                    enable-max-option-width-->
<!--                    small-size-->
<!--                    show-delete-button-->
<!--                    v-model="locationInFiltersData"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :no-options-found="$t('general.noResultForSearch')"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="selectLocationInFilters"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <div class="ml-h mt-1">-->
<!--                        <Switch-component-->
<!--                          @input="refreshWlansRating"-->
<!--                          :label="$t('general.withChild')"-->
<!--                          v-model="filters.with_childs"-->
<!--                          class=""-->
<!--                        />-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->

<!--          </div>-->
<!--          <div class="input-group input-group-sm filters__location" v-else>-->
<!--            <span class="input-group-addon filters__field-icon">-->
<!--              <i class="fa fa-map-marker"></i>-->
<!--            </span>-->
<!--            <select-->
<!--              @change="refreshWlansRating"-->
<!--              class="form-control form-control-sm filters__field"-->
<!--              v-model="filters.location"-->
<!--              @input="disableStatMode()"-->
<!--            >-->
<!--              <option-->
<!--                v-for="location in locationsObjbyIds(this.filters.company.locations)"-->
<!--                :value="location.id"-->
<!--                :key="location.id"-->
<!--              >-->
<!--                {{ location.name }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--          &lt;!&ndash;                             <div&ndash;&gt;-->
<!--          &lt;!&ndash;                 v-if="(!(this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys')) && this.filters.location !== 'All locations'"&ndash;&gt;-->
<!--          &lt;!&ndash;                 class="d-flex justify-content-start align-items-center  with-child-wrapper ">&ndash;&gt;-->
<!--          &lt;!&ndash;                <div>&ndash;&gt;-->
<!--          &lt;!&ndash;                <input&ndash;&gt;-->
<!--          &lt;!&ndash;                  :disabled="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'"&ndash;&gt;-->
<!--          &lt;!&ndash;                  :title="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'? $t('aps.filterByCompanyDisableSortingByLocationMsg') : ''"&ndash;&gt;-->
<!--          &lt;!&ndash;                  type="checkbox"&ndash;&gt;-->
<!--          &lt;!&ndash;                  v-model="filters.with_childs"&ndash;&gt;-->
<!--          &lt;!&ndash;                  @change="refreshWlansRating"&ndash;&gt;-->
<!--          &lt;!&ndash;                />&ndash;&gt;-->
<!--          &lt;!&ndash;              </div>&ndash;&gt;-->
<!--          &lt;!&ndash;              <div>&ndash;&gt;-->
<!--          &lt;!&ndash;                <small class="with-child">{{$t('general.withChild')}}</small>&ndash;&gt;-->
<!--          &lt;!&ndash;              </div>&ndash;&gt;-->

<!--          &lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="d-flex switcher-with-childs-in-stat-wlans-wrapper align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;            <div&ndash;&gt;-->
<!--&lt;!&ndash;              :title="&ndash;&gt;-->
<!--&lt;!&ndash;                this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'&ndash;&gt;-->
<!--&lt;!&ndash;                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')&ndash;&gt;-->
<!--&lt;!&ndash;                  : ''&ndash;&gt;-->
<!--&lt;!&ndash;              "&ndash;&gt;-->
<!--&lt;!&ndash;              class="d-flex align-items-end justify-content-start"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <Switch-component&ndash;&gt;-->
<!--&lt;!&ndash;                :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="filters.with_childs"&ndash;&gt;-->
<!--&lt;!&ndash;                class="switcher-with-childs-in-stat-wlans"&ndash;&gt;-->
<!--&lt;!&ndash;                @change="refreshWlansRating"&ndash;&gt;-->
<!--&lt;!&ndash;              />&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="input-group input-group-sm filters__location switcher-with-childs-in-stat-wlans-caption">&ndash;&gt;-->
<!--&lt;!&ndash;              <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;           <div class="col-lg-4 col-md-12">&ndash;&gt;-->
<!--        &lt;!&ndash;            <div class="input-group input-group-sm filters__location ">&ndash;&gt;-->
<!--        &lt;!&ndash;                <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--        &lt;!&ndash;                  <i class="fa fa-building-o"&ndash;&gt;-->
<!--        &lt;!&ndash;                     :class="{ 'text-primary': filters.company !== 'All companys' }"></i>&ndash;&gt;-->
<!--        &lt;!&ndash;                </span>&ndash;&gt;-->
<!--        &lt;!&ndash;              <select&ndash;&gt;-->
<!--        &lt;!&ndash;                @change="refreshWlansRating"&ndash;&gt;-->
<!--        &lt;!&ndash;                @input="disableStatMode()"&ndash;&gt;-->
<!--        &lt;!&ndash;                class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--        &lt;!&ndash;                v-model="filters.company"&ndash;&gt;-->
<!--        &lt;!&ndash;              >&ndash;&gt;-->
<!--        &lt;!&ndash;                <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}&ndash;&gt;-->
<!--        &lt;!&ndash;                </option>&ndash;&gt;-->
<!--        &lt;!&ndash;                <option v-for="company in companyList" :value="company" :key="company.name">&ndash;&gt;-->
<!--        &lt;!&ndash;                  {{ company.name}}&ndash;&gt;-->
<!--        &lt;!&ndash;                </option>&ndash;&gt;-->
<!--        &lt;!&ndash;              </select>&ndash;&gt;-->
<!--        &lt;!&ndash;            </div>&ndash;&gt;-->
<!--        &lt;!&ndash;          </div>&ndash;&gt;-->
<!--        &lt;!&ndash;                    <div class="col-lg-4 col-md-12 d-flex">&ndash;&gt;-->
<!--        &lt;!&ndash;            <div class="input-group input-group-sm filters__location ">&ndash;&gt;-->
<!--        &lt;!&ndash;              <span class="input-group-addon filters__field-icon">&ndash;&gt;-->
<!--        &lt;!&ndash;                <i class="fa fa-map-marker"></i>&ndash;&gt;-->
<!--        &lt;!&ndash;              </span>&ndash;&gt;-->
<!--        &lt;!&ndash;              <select&ndash;&gt;-->
<!--        &lt;!&ndash;                @change="refreshWlansRating"&ndash;&gt;-->
<!--        &lt;!&ndash;                class="form-control form-control-sm filters__field"&ndash;&gt;-->
<!--        &lt;!&ndash;                v-model="filters.location"&ndash;&gt;-->
<!--        &lt;!&ndash;                @input="disableStatMode()"&ndash;&gt;-->
<!--        &lt;!&ndash;                :title="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'? $t('aps.filterByCompanyDisableSortingByLocationMsg') : ''"&ndash;&gt;-->
<!--        &lt;!&ndash;                :disabled="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'"&ndash;&gt;-->
<!--        &lt;!&ndash;              >&ndash;&gt;-->
<!--        &lt;!&ndash;                <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>&ndash;&gt;-->
<!--        &lt;!&ndash;                <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
<!--        &lt;!&ndash;                  {{ location.name }}&ndash;&gt;-->
<!--        &lt;!&ndash;                </option>&ndash;&gt;-->
<!--        &lt;!&ndash;              </select>&ndash;&gt;-->
<!--        &lt;!&ndash;            </div>&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                             <div&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                 v-if="(!(this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys')) && this.filters.location !== 'All locations'"&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                 class="d-flex justify-content-start align-items-center  with-child-wrapper ">&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                <div>&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                <input&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                  :disabled="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'"&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                  :title="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'? $t('aps.filterByCompanyDisableSortingByLocationMsg') : ''"&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                  type="checkbox"&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                  v-model="filters.with_childs"&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                  @change="refreshWlansRating"&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                />&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;              </div>&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;              <div>&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;                <small class="with-child">{{$t('general.withChild')}}</small>&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;              </div>&ndash;&gt;&ndash;&gt;-->

<!--        &lt;!&ndash;&lt;!&ndash;            </div>&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;                                     <div class="d-flex  switcher-with-childs-in-stat-wlans-wrapper align-items-center">&ndash;&gt;-->

<!--        &lt;!&ndash;                 <div&ndash;&gt;-->
<!--        &lt;!&ndash;                   :title="this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys'? $t('aps.filterByCompanyDisableSortingByLocationMsg') : ''"&ndash;&gt;-->
<!--        &lt;!&ndash;                   class="d-flex align-items-end justify-content-start">&ndash;&gt;-->
<!--        &lt;!&ndash;                   <Switch-component&ndash;&gt;-->
<!--        &lt;!&ndash;                     :disabled="(this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys') || this.filters.location === 'All locations'"&ndash;&gt;-->
<!--        &lt;!&ndash;                     v-model="filters.with_childs"&ndash;&gt;-->
<!--        &lt;!&ndash;                     class='switcher-with-childs-in-stat-wlans'&ndash;&gt;-->
<!--        &lt;!&ndash;                     @change="refreshWlansRating"&ndash;&gt;-->

<!--        &lt;!&ndash;                   />&ndash;&gt;-->
<!--        &lt;!&ndash;                 </div>&ndash;&gt;-->
<!--        &lt;!&ndash;                 <div class=" input-group input-group-sm filters__location switcher-with-childs-in-stat-wlans-caption">&ndash;&gt;-->
<!--        &lt;!&ndash;                   <small class="with-child">{{$t('general.withChild')}}</small>&ndash;&gt;-->
<!--        &lt;!&ndash;                 </div>&ndash;&gt;-->

<!--        &lt;!&ndash;               </div>&ndash;&gt;-->
<!--        &lt;!&ndash;            </div>&ndash;&gt;-->

<!--        <div class="col-lg-4 col-md-12">-->
<!--          <div class="input-group input-group-sm filters__sort mr-1">-->
<!--            <span class="input-group-addon filters__field-icon">-->
<!--              <i-->
<!--                class="fa fa-sort-amount-desc text-primary icon-sort"-->
<!--                v-if="currSortMode === 'desc'"-->
<!--                @click="toggleSortMode()"-->
<!--              ></i>-->
<!--              <i-->
<!--                class="fa fa-sort-amount-asc text-primary icon-sort"-->
<!--                v-if="currSortMode === 'asc'"-->
<!--                @click="toggleSortMode()"-->
<!--              ></i>-->
<!--            </span>-->
<!--            <select class="form-control form-control-sm filters__field" v-model="sortByFilter">-->
<!--              <option :selected="true" value="Traffic">{{ $t('statistics.byTraffic') }}</option>-->
<!--              <option value="Clients">{{ $t('statistics.bytotalClients') }}</option>-->
<!--              &lt;!&ndash;<option>&ndash;&gt;-->
<!--              &lt;!&ndash;Unique Clients&ndash;&gt;-->
<!--              &lt;!&ndash;</option>&ndash;&gt;-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row mb-1">-->
<!--        <div class="col-12">-->
<!--          <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">-->
<!--            <i class="fa fa-close"></i>-->
<!--            {{ $t('general.resetAllFilters') }}-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->




    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-3 col-sm-12  filters mb-h">
          <div class="">
            <div class="input-group input-group-sm">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
              <input
                id="search"
                name="search"
                :placeholder="$t('aps.search')"
                class="form-control filters__input"
                v-model="filters.query"
                @input="disableStatMode()"
              />
              <span class="clear-input text-muted" v-if="filters.query != ''" @click="filters.query = ''">
                <i class="fa fa-remove"></i>
              </span>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-12 mb-h" v-if="areLocationsVisible">
          <div class="input-group input-group-sm filters__location">
            <span class="input-group-addon filters__field-icon">
              <i class="fa fa-building-o" :class="{ 'text-primary': filters.company !== 'All companys' }"></i>
            </span>
            <select
              :disabled="isSelectedDefaultLocationForRequests"
              @change="changeCompany"
              @input="disableStatMode()"
              class="form-control form-control-sm filters__field"
              v-model="filters.company"
            >
              <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>
              <option v-for="company in companys" :value="company" :key="company.name">
                {{ company.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-12  mb-h d-flex" v-if="areLocationsVisible">
          <div class="input-group input-group-sm filters__location input-group-for-location-select-in-statwlans--filters" v-if="filters.company === 'All companys'">



                                                       <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-statwlans--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="refreshWlansRating"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>

          </div>
          <div class="input-group input-group-sm filters__location" v-else>
            <span class="input-group-addon filters__field-icon">
              <i class="fa fa-map-marker"></i>
            </span>
            <select
              @change="refreshWlansRating"
              class="form-control form-control-sm filters__field"
              v-model="filters.location"
              @input="disableStatMode()"
            >
              <option
                v-for="location in locationsObjbyIds(this.filters.company.locations)"
                :value="location.id"
                :key="location.id"
              >
                {{ location.name }}
              </option>
            </select>
          </div>

        </div>


        <div class="col-lg-3 col-md-12 mb-h">
          <div class="input-group input-group-sm filters__sort mr-1">
            <span class="input-group-addon filters__field-icon">
              <i
                class="fa fa-sort-amount-desc text-primary icon-sort"
                v-if="currSortMode === 'desc'"
                @click="toggleSortMode()"
              ></i>
              <i
                class="fa fa-sort-amount-asc text-primary icon-sort"
                v-if="currSortMode === 'asc'"
                @click="toggleSortMode()"
              ></i>
            </span>
            <select class="form-control form-control-sm filters__field" v-model="sortByFilter">
              <option :selected="true" value="Traffic">{{ $t('statistics.byTraffic') }}</option>
              <option value="Clients">{{ $t('statistics.bytotalClients') }}</option>
              <!--<option>-->
              <!--Unique Clients-->
              <!--</option>-->
            </select>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-12">
          <WButton secondary outline sm
            v-if="isFiltersActive"
            @click="resetAllFilters"
          >
            <i class="fa fa-close"></i>
            {{ $t('general.resetAllFilters') }}
          </WButton>
        </div>
      </div>
    </div>


    <div class="col-lg-12">
      <div class="cpes" :class="{ 'stat-visible': statVisible }">
        <div class="wlans-rating-list animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <!--                  <div class="loader loader-backdrop loader-backdrop-statwlans" v-if="isWlansRatingLoading"></div>-->
                  <div v-if="isWlansRatingLoading"><Loader-spinner></Loader-spinner></div>
                  <div class="card-header">
                    <i class="fa fa-bar-chart"></i>
                    {{ $t('wlans.wlans') }}

                    <div class="float-right d-flex flex-column align-items-start justify-content-start">
                      <div>
                        <date-picker
                          v-if="!statVisible"
                          v-model="drPickerDateForRating"
                          :onChange="handleDateChangeForRating"
                          class="float-right"
                          @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceededForRating"
                          :show-error="isShowingDateRangeLimitExceededErrorForRating"
                          enable-max-date-range-limit
                        />
                      </div>
                      <div>
                        <div
                          v-show="isShowingDateRangeLimitExceededErrorForRating === true && !statVisible"
                          class="mb-h mb-1"
                        >
                          <span
                            style="font-size: 0.8em;"
                            v-show="isShowingDateRangeLimitExceededErrorForRating === true"
                            class="help is-danger"
                          >
                            {{ $t('general.dateRangeLimitExceededError') }}
                            {{ moment.duration(dateRangeLimitExceededErrorRangeLimitForRating).humanize() }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-block">
                    <table class="table">
                      <thead>
                        <tr>
                          <th v-if="!statVisible"></th>
                          <th>SSID</th>
                          <th v-if="!statVisible">{{ $t('general.trafficTxRx') }}</th>
                          <th v-if="!statVisible">{{ $t('general.clients') }}</th>
                          <th v-if="!statVisible && areLocationsVisible">{{ $t('general.location') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(wlan, index) in filterItems(wlansRatingListFull)"
                          :data-index="index"
                          :data-id="wlan.id"
                          :key="wlan.id"
                          v-if="wlanObj(wlan.id)"
                          class="table-row"
                          @click="toggleEdit"
                          :class="{
                            selected: wlanSelectedId === wlan.id,
                            'disabled-when-date-range-limit-exceeded-error':
                              isShowingDateRangeLimitExceededErrorForStat && statVisible && wlanSelectedId !== wlan.id
                          }"
                        >
                          <td v-if="!statVisible">{{ index + 1 }}</td>
                          <td class="responsive-row">{{ wlanObj(wlan.id).ssid }}</td>
                          <td v-if="!statVisible" :class="{ 'cpe-row--sorted': sortByFilter === 'Traffic' }">
                            <span v-if="wlan.traffic_rx || wlan.traffic_rx == 0">
                              {{ formatBytes(wlan.traffic_rx) }} /
                            </span>

                            <span v-if="wlan.traffic_tx || wlan.traffic_tx == 0">
                              {{ formatBytes(wlan.traffic_tx) }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td
                            v-if="!statVisible"
                            class="responsive-row"
                            :class="{ 'cpe-row--sorted': sortByFilter === 'Clients' }"
                          >
                            <span v-if="wlan.clients || wlan.clients == 0">{{ wlan.clients }}</span>
                            <span v-else>-</span>
                          </td>
                          <td v-if="!statVisible && areLocationsVisible" class="responsive-row">{{ locationName(wlan.base_location) }}</td>
                        </tr>
                        <tr v-if="!filterItems(wlansRatingListFull).length || !wlansRatingListLength">
                          <td colspan="6" class="no-data-row">
                            <span class="text-muted">{{ $t('general.noDataToDisplay') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wlan-stat" v-if="statVisible">
          <div class="row">
            <div class="col-lg-12">
              <div class="card sticky-top">
                <!--                <div class="loader loader-backdrop loader-backdrop-statwlans" v-if="isStatsLoading"></div>-->
                <div v-if="isStatsLoading"><Loader-spinner></Loader-spinner></div>
                <div class="card-header">
                  <i class="fa fa-wifi"></i>
                  {{ wlanObj(statVisible).ssid }}
                  <WButton secondary outline sm
                    customClass="float-right"
                    @click="disableStatMode"
                  >
                    <i class="fa fa-close"></i>
                  </WButton>
                </div>
                <div class="card-block">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="float-left">
                        <WButton secondary
                          v-if="showGoToCpesButton"
                          @click="goToWlans"
                        >
                          {{ $t('statistics.goToWLANSettings') }}
                        </WButton>
                      </div>
                      <div class="float-right d-flex flex-column justify-content-start align-items-start">
                        <div>
                          <date-picker
                            v-model="drPickerDateForStat"
                            :onChange="handleDateChangeForStat"
                            @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceededForStat"
                            :show-error="isShowingDateRangeLimitExceededErrorForStat"
                            enable-max-date-range-limit
                          />
                        </div>
                        <div>
                          <div v-show="isShowingDateRangeLimitExceededErrorForStat === true" class="mb-h mb-1">
                            <span
                              style="font-size: 0.8em;"
                              v-show="isShowingDateRangeLimitExceededErrorForStat === true"
                              class="help is-danger"
                            >
                              {{ $t('general.dateRangeLimitExceededError') }}
                              {{ moment.duration(dateRangeLimitExceededErrorRangeLimitForStat).humanize() }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row mt-1">
                      <div class="col-lg-12">
                        <highcharts :options="txRxChart" id="txRxChart" ref="highcharts"></highcharts>
                        <SavePngButton class="save-icon color-brand" id="txRxChart" :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statWLANs')}-${wlanObj(statVisible).ssid ? wlanObj(statVisible).ssid : '' }-${$t('statistics.txRxChartTitle')}`)" />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12">
                        <highcharts :options="txRxRateChart" id="txRxRateChart" ref="highcharts"></highcharts>
                        <SavePngButton class="save-icon color-brand" id="txRxRateChart" :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statWLANs')}-${wlanObj(statVisible).ssid ? wlanObj(statVisible).ssid : '' }-${$t('statistics.txRxRateChartTitle')}`)" />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12">
                        <highcharts
                          :options="txRxRateChartPerUser"
                          id="txRxRateChartPerUser"
                          ref="highcharts"
                        ></highcharts>
                        <SavePngButton
                          class="save-icon color-brand"
                          id="txRxRateChartPerUser"
                          :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statWLANs')}-${wlanObj(statVisible).ssid ? wlanObj(statVisible).ssid : '' }-${$t('statistics.txRxRatePerUserChartTitle')}`)"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12">
                        <highcharts :options="clientsChart" id="clientsChart" ref="highcharts"></highcharts>
                        <SavePngButton class="save-icon color-brand" id="clientsChart" :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statWLANs')}-${wlanObj(statVisible).ssid ? wlanObj(statVisible).ssid : '' }-${$t('statistics.totalClientsChartTitle')}`)" />
                      </div>
                    </div>
                    <div class="actions-buttons">
                      <div>
                        <WButton secondary outline
                          @click="disableStatMode"
                        >
                          {{ $t('general.close') }}
                        </WButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import statWlanService from '../../services/statWlanService';
import commonService from '../../services/commonService';
import Filters from '../../filters';
import drPicker from '../../components/date-range-picker.vue';
import SavePngButton from '../../components/save-png-button.vue';
import DatePicker from '../../components/date-picker.vue';
import helpers from '../../helpers';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import locationService from '../../services/locationService';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

export default {
  name: 'Statwlans',
  components: {
    drPicker, SavePngButton, DatePicker, SwitchComponent, SelectComponentV2, SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      isShowingDateRangeLimitExceededErrorForRating: false,
      dateRangeLimitExceededErrorRangeLimitForRating: 0,
      isShowingDateRangeLimitExceededErrorForStat: false,
      dateRangeLimitExceededErrorRangeLimitForStat: 0,
      searchQuery: '',
      enableStats: '',
      currentWlanStats: [],
      filteredWlans: [],
      statWlanId: '',
      drPickerDateForRating: {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
      },
      drPickerDateForStat: {
        startDate: '',
        endDate: ''
      },
      filters: {
        query: '',
        location: 'All locations',
        company: 'All companys',
        with_childs: false
      },
      sortByFilter: 'Traffic',
      sortMode: 'desc',

      txRxChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('statistics.txRxChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.$t('statistics.txRxChartMbytes')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y} ${this.$t('statistics.txRxChartMbytes')}</b>`
        },
        series: [
          {
            name: this.$t('statistics.txRxChartTX'),
            data: [],
            color: '#0277BD'
          },
          {
            name: this.$t('statistics.txRxChartRX'),
            data: [],
            color: '#81C784'
          },
          {
            name: this.$t('statistics.txRxChartTXandRX'),
            data: [],
            color: '#E57373'
          }
        ]
      },

      cpuCharts: {
        chart: {
          type: 'area',
          height: '300',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: 'CPU load',
          x: -20 // center
        },
        plotOptions: {
          series: {
            fillOpacity: 0.5
          }
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: 'CPU load'
          },
          labels: {
            formatter() {
              return `${this.value} %`;
            }
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        tooltip: {
          pointFormat: '<b>{point.y} %</b>'
        },
        series: [
          {
            name: 'CPU',
            color: '#E57373',
            data: []
          }
        ]
      },

      clientsChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('statistics.totalClientsChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: this.$t('statistics.totalClientsChartAxis')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        series: [
          {
            name: this.$t('statistics.totalClientsChartSeries'),
            data: [],
            color: '#20a8d8'
          }
        ]
      },

      uniqueClientsChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: 'Unique Clients',
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: 'Clients'
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        series: [
          {
            name: 'Clients',
            data: [],
            color: '#81C784'
          }
        ]
      },

      memoryCharts: {
        chart: {
          type: 'area',
          height: '300',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: 'Memory load',
          x: -20 // center
        },
        plotOptions: {
          series: {
            fillOpacity: 0.5
          }
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: 'Memory load'
          },
          labels: {
            formatter() {
              return `${this.value} %`;
            }
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#000000'
            }
          ]
        },
        tooltip: {
          pointFormat: '<b>{point.y} %</b>'
        },
        series: [
          {
            name: 'Memory load',
            color: '#0277BD',
            data: []
          }
        ]
      },

      txRxRateChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('statistics.txRxRateChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.$t('statistics.txRxRateChartMbytess')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#000000'
            }
          ]
        },
        series: [
          {
            name: this.$t('statistics.txRxRateChartTXRate'),
            data: [],
            color: '#0277BD'
          },
          {
            name: this.$t('statistics.txRxRateChartRXRate'),
            data: [],
            color: '#81C784'
          },
          {
            name: this.$t('statistics.txRxRateChartTXandRXRate'),
            data: [],
            color: '#E57373'
          }
        ]
      },
      txRxRateChartPerUser: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: this.$t('statistics.txRxRatePerUserChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: this.$t('statistics.txRxRatePerUserChartMbytess')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#000000'
            }
          ]
        },
        series: [
          {
            name: this.$t('statistics.txRxRatePerUserChartTXRate'),
            data: [],
            color: '#0277BD'
          },
          {
            name: this.$t('statistics.txRxRatePerUserChartRXRate'),
            data: [],
            color: '#81C784'
          },
          {
            name: this.$t('statistics.txRxRatePerUserChartTXandRXRate'),
            data: [],
            color: '#E57373'
          }
        ]
      }
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    showGoToCpesButton(){
      return this.$store.state.userData.role === 'operator' ||
        this.$store.state.userData.role === 'admin';
    },
    wlansRatingListLength() {
      return this.$store.state.wlansRatingList.length;
    },
    companys() {
      return this.$store.getters.getCompanyList;
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    companyList() {
      return this.$store.getters.companyListSorted;
    },
    isStatsLoading() {
      return this.$store.state.loadingStats;
    },
    isFiltersActive() {
      return (
        this.filters.query !== '' ||
          this.sortMode !== 'desc' || this.sortByFilter !== 'Traffic' ||
           this.filters.company !== 'All companys' ||
         (this.areLocationsVisible ? this.filters.location !== 'All locations' : false)

      );
    },
    statVisible() {
      return this.$store.state.statWlanId;
    },
    wlanSelectedId() {
      return this.$store.state.statWlanId;
    },
    wlansRatingListFull() {
      if (this.$store.state.wlansRatingList && this.$store.state.wlansList) {
        const wlansRatingListFull = this.$store.state.wlansRatingList;

        wlansRatingListFull.forEach((wlan) => {
          const findWlan = commonService.wlanObjbyId(wlan.id);
          if (findWlan) {
            wlan.ssid = findWlan.ssid;
            wlan.base_location = findWlan.base_location;
          }
        });
        return wlansRatingListFull;
      }
    },
    isWlansRatingLoading() {
      return this.$store.state.loadingWlansRating;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    currSortMode() {
      return this.sortMode;
    }
  },
  methods: {
    goToWlans() {
      this.saveComponentStateDataForRestore();
      this.$router.push({ name: 'Wlans', params: { wlanIdFormWlanStatisic: this.wlanSelectedId} });
    },

    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshWlansRating()

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },

    saveComponentStateDataForRestore() {
      // из стора
      const wlansRatingList = JSON.parse(JSON.stringify(this.$store.state.wlansRatingList));
      const statWlanId = this.$store.state.statWlanId;
      const wlans = JSON.parse(JSON.stringify(this.$store.state.wlansList))

      // из стейта компонента
      const filters = JSON.parse(JSON.stringify(this.filters));
      const drPickerDateForRating = JSON.parse(JSON.stringify(this.drPickerDateForRating));
      const drPickerDateForStat = JSON.parse(JSON.stringify(this.drPickerDateForStat));
      const locationInFiltersData = JSON.parse(JSON.stringify(this.locationInFiltersData));

      // const txRxChart = JSON.parse(JSON.stringify(this.txRxChart));
      // const txRxRateChart = JSON.parse(JSON.stringify(this.txRxRateChart));
      // const txRxRateChartPerUser = JSON.parse(JSON.stringify(this.txRxRateChartPerUser));
      // const clientsChart = JSON.parse(JSON.stringify(this.clientsChart));
      const offset = this.offset;
      const totalResult = this.totalResult;
      const canLoadMore = this.canLoadMore;
      const sortByFilter = this.sortByFilter;
      const sortMode = this.sortMode;

      const wlansStatisticComponentStateDataForRestoreInWlansStatisticPage = {
        wlansRatingList,
        statWlanId,
        wlans,
        filters,
        drPickerDateForRating,
        drPickerDateForStat,
        // txRxChart,
        // txRxRateChart,
        // txRxRateChartPerUser,
        // clientsChart,
        offset,
        totalResult,
        canLoadMore,
        sortByFilter,
        sortMode,
        locationInFiltersData
      };
      localStorage.setItem('wlansStatisticComponentStateDataForRestoreInWlansStatisticPage',
        JSON.stringify(wlansStatisticComponentStateDataForRestoreInWlansStatisticPage));

    },
    loadComponentStateDataForRestoreAndRestore() {
      const wlansStatisticComponentStateDataForRestoreInWlansStatisticPage = JSON.parse(localStorage.getItem('wlansStatisticComponentStateDataForRestoreInWlansStatisticPage'));
      if (wlansStatisticComponentStateDataForRestoreInWlansStatisticPage) {
        // восстанавливаем в стор
        this.$store.commit('setWlansList', wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.wlans ?? false)
        this.$store.commit('setWlansRatingList', wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.wlansRatingList ?? [])


        // восстанавливаем в стейт компонента
        if (!!wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.filters) {
          this.$set(this, 'filters',
            JSON.parse(JSON.stringify(wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.filters)))
        }

        if (!!wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.drPickerDateForRating) {
          this.$set(this, 'drPickerDateForRating',
            JSON.parse(JSON.stringify(wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.drPickerDateForRating)))
        }

        if (!!wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.drPickerDateForStat) {
          this.$set(this, 'drPickerDateForStat',
            JSON.parse(JSON.stringify(wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.drPickerDateForStat)))
        }

        if (!!wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.locationInFiltersData) {
          this.$set(this, 'locationInFiltersData',
            JSON.parse(JSON.stringify(wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.locationInFiltersData)))
        }

        // if (!!wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.txRxChart) {
        //   this.$set(this, 'txRxChart',
        //     JSON.parse(JSON.stringify(wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.txRxChart)))
        // }
        // if (!!wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.txRxRateChart) {
        //   this.$set(this, 'txRxRateChart',
        //     JSON.parse(JSON.stringify(wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.txRxRateChart)))
        // }
        // if (!!wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.txRxRateChartPerUser) {
        //   this.$set(this, 'txRxRateChartPerUser',
        //     JSON.parse(JSON.stringify(wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.txRxRateChartPerUser)))
        // }
        // if (!!wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.clientsChart) {
        //   this.$set(this, 'clientsChart',
        //     JSON.parse(JSON.stringify(wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.clientsChart)))
        // }

        this.offset = wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.offset;
        this.totalResult = wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.totalResult;
        this.canLoadMore = wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.canLoadMore;
        this.sortByFilter = wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.sortByFilter;
        this.sortMode = wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.sortMode;


        this.$nextTick(() => {
          this.$store.commit('setWlanStatId', wlansStatisticComponentStateDataForRestoreInWlansStatisticPage.statWlanId ?? null);
          this.responseStats();
        });
      }
    },
    removeComponentStateDataForRestore(){
      localStorage.removeItem('wlansStatisticComponentStateDataForRestoreInWlansStatisticPage');
    },
    checkComponentStateDataForRestore(){
      return !!localStorage.getItem('wlansStatisticComponentStateDataForRestoreInWlansStatisticPage');
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    dateRangeLimitEnabledAndExceededForRating(payload) {
      if (payload.dateRangeLimitEnabledAndExceeded === true) {
        this.isShowingDateRangeLimitExceededErrorForRating = true;
        this.dateRangeLimitExceededErrorRangeLimitForRating = payload.maxDateRangeLimit;
        this.$store.commit('setWlansRatingList', []);
      } else {
        this.isShowingDateRangeLimitExceededErrorForRating = false;
        this.dateRangeLimitExceededErrorRangeLimitForRating = payload.maxDateRangeLimit;
      }
    },
    dateRangeLimitEnabledAndExceededForStat(payload) {
      // console.log(payload)
      if (payload.dateRangeLimitEnabledAndExceeded === true) {
        this.isShowingDateRangeLimitExceededErrorForStat = true;
        this.dateRangeLimitExceededErrorRangeLimitForStat = payload.maxDateRangeLimit;
        this.refreshCharts();
      } else {
        this.isShowingDateRangeLimitExceededErrorForStat = false;
        this.dateRangeLimitExceededErrorRangeLimitForStat = payload.maxDateRangeLimit;
      }
    },
    changeCompany() {
      // console.log(val)
      let firstLocationIdForCompany = '';
      if (this.filters.company.hasOwnProperty('locations') && this.filters.company.locations.length > 0) {
        firstLocationIdForCompany = this.filters.company.locations[0];
        // console.log(firstLocationIdForCompany);
      } else {
        firstLocationIdForCompany = 'All locations';
        if (this.$store.state.userData.base_location !== '') {
          firstLocationIdForCompany = this.$store.state.userData.base_location;
          if (firstLocationIdForCompany) {
            this.locationInFiltersData = commonService.locationObjbyId(firstLocationIdForCompany)
          }
        }
      }
      // console.log(firstLocationIdForCompany)
      // this.updatedPage.base_location = firstLocationIdForCompany;
      // this.filters.location = firstLocationIdForCompany;
      // this.filters.with_childs = false;
      this.$set(this.filters, 'location', firstLocationIdForCompany);
      this.$set(this.filters, 'with_childs', false);
      this.refreshWlansRating();
    },
    locationsObjbyIds(ids) {
      return commonService.locationsObjbyIds(ids);
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters.query = '';
    //   // this.filters.location = this.userBaseLocation;
    //   this.filters.location = 'All locations';
    //   this.filters.company = 'All companys';
    //   this.sortByFilter = 'Traffic';
    //   this.sortMode = 'desc';
    //   this.filters.with_childs = false;
    //   this.refreshWlansRating();
    // },
    resetAllFilters() {
    //  this.locationInFiltersData = [];
     // this.toggleShowLocationFilter();
      this.filters.query = '';
      // this.filters.location = this.userBaseLocation;
      // this.filters.location = 'All locations';
      this.filters.company = 'All companys';
      this.sortByFilter = 'Traffic';
      this.sortMode = 'desc';
      // this.filters.with_childs = false;
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      this.refreshWlansRating();
    },
    toggleEdit(e) {
      if (this.isShowingDateRangeLimitExceededErrorForStat && this.statVisible) {
        return;
      }

      const selectedNode = e.target.closest('[data-index]');
      const { id } = selectedNode.dataset;
      this.$store.commit('setWlanStatId', id);
      this.responseStats();
    },
    percentRxTx(rx, tx) {
      const rxPercent = (rx * 100) / (rx + tx);
      if (rxPercent > 1) {
        return Math.round(rxPercent);
      }
      return Math.round(rxPercent * 10) / 10;
    },
    filterItems(items) {
      let filteredWlans = items;
      filteredWlans = Filters.wlanSearchQuery(filteredWlans, this.filters.query);
      // if (this.filters.hasOwnProperty('company') && this.filters.company !== 'All companys') {
      //   this.filters.location = this.filters.location = 'All locations';
      //   filteredWlans = Filters.locationFilter(filteredWlans, this.filters.company.locationId);
      // }
      // filteredWlans = Filters.locationFilter(filteredWlans, this.filters.location);
      this.filteredWlans = filteredWlans;
      let sortedFilteredWlans = [];
      let first;
      let second;
      if (this.sortMode === 'desc') {
        first = -1;
        second = 1;
      } else if (this.sortMode === 'asc') {
        first = 1;
        second = -1;
      }

      if (this.sortByFilter === 'Traffic') {
        sortedFilteredWlans = filteredWlans.sort((curr, prev) => {
          if (curr.traffic_rx > prev.traffic_rx) {
            return first;
          }
          if (curr.traffic_rx < prev.traffic_rx) {
            return second;
          }
          return 0;
        });
      } else if (this.sortByFilter === 'Clients') {
        sortedFilteredWlans = filteredWlans.sort((curr, prev) => {
          if (curr.clients > prev.clients) {
            return first;
          }
          if (curr.clients < prev.clients) {
            return second;
          }
          return 0;
        });
      } else if (this.sortByFilter === 'Unique Clients') {
        sortedFilteredWlans = filteredWlans.sort((curr, prev) => {
          if (curr.unique_clients > prev.unique_clients) {
            return first;
          }
          if (curr.unique_clients < prev.unique_clients) {
            return second;
          }
          return 0;
        });
      }
      // console.log('sortedFilteredWlans', sortedFilteredWlans)
      return sortedFilteredWlans;
    },
    setFilter(filterName, value) {
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
        return;
      }
      this.filters[filterName] = '';
    },
    toggleSortMode() {
      if (this.sortMode === 'desc') {
        this.sortMode = 'asc';
      } else {
        this.sortMode = 'desc';
      }
    },
    disableStatMode() {
      this.drPickerDateForStat = {
        startDate: '',
        endDate: ''
      };
      this.isShowingDateRangeLimitExceededErrorForStat = false;
      this.dateRangeLimitExceededErrorRangeLimitForStat = 0;

      this.statWlanId = '';
      this.$store.commit('setWlanStatId', null);
    },
    locationName(id) {
      return commonService.showLocationFromId(id);
    },
    cutName(name) {
      return commonService.cutName(name);
    },
    // handleDateChangeForRating() {
    //   // statWlanService.getWlansRating(this);
    //   this.refreshWlansRating();
    // },
    // handleDateChangeForStat() {
    //   statWlanService.getWlanStat(this);
    // },
    handleDateChangeForRating() {
      if (!this.isShowingDateRangeLimitExceededErrorForRating) {
        this.refreshWlansRating();
      }
    },
    handleDateChangeForStat() {
      if (!this.isShowingDateRangeLimitExceededErrorForStat) {
        statWlanService.getWlanStat(this);
      }
    },
    refreshCharts() {
      console.log('refresh');
      this.cpuCharts.series[0].data = [];
      this.memoryCharts.series[0].data = [];
      this.txRxChart.series[0].data = [];
      this.txRxChart.series[1].data = [];
      this.txRxChart.series[2].data = [];
      this.clientsChart.series[0].data = [];
      this.uniqueClientsChart.series[0].data = [];
      this.txRxRateChartPerUser.series[0].data = [];
      this.txRxRateChartPerUser.series[1].data = [];
      this.txRxRateChartPerUser.series[2].data = [];
      this.txRxRateChart.series[0].data = [];
      this.txRxRateChart.series[1].data = [];
      this.txRxRateChart.series[2].data = [];
    },
    getWlansRating() {
      // statWlanService.getWlansRating(this);
      statWlanService.getWlansRatingWithLocationFilter(this);
    },
    refreshWlansRating() {
      statWlanService.getWlansRatingWithLocationFilter(this);
    },

    responseStats() {
      statWlanService.getWlanStat(this);
    },
    formatBytes(bytes, decimals) {
      return commonService.formatBytes(bytes, decimals);
    },
    wlanObj(id) {
      return commonService.wlanObjbyId(id);
    }
  },
  // created() {
  //   this.filters.location = this.userBaseLocation;
  //   this.$store.commit('setCpesList', false);
  //   this.getWlansRating();
  //   this.$store.commit('setWlanStatId', null);
  // },
  // created() {
  //   this.$store.commit('setWlanStatId', null);
  //   this.$store.commit('setCpesList', false);
  //   const hasLocalStorageDataForRestoring = this.checkComponentStateDataForRestore();
  //   const goBackFromWlans = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromWlans');
  //   const needRestoreState = hasLocalStorageDataForRestoring && goBackFromWlans;
  //   if (needRestoreState) {
  //     this.loadComponentStateDataForRestoreAndRestore();
  //     this.removeComponentStateDataForRestore();
  //   }
  //   if (!needRestoreState && hasLocalStorageDataForRestoring) {
  //     this.removeComponentStateDataForRestore();
  //   }
  //
  //   if (!needRestoreState) {
  //     this.filters.location = this.userBaseLocation;
  //     // this.$store.commit('setCpesList', false);
  //     this.getWlansRating();
  //     // this.$store.commit('setWlanStatId', null);
  //   }
  // },
  created() {
    let inHeaderLocationSelectedApplying = true;
    this.$store.commit('setWlanStatId', null);
    this.$store.commit('setCpesList', false);
    const hasLocalStorageDataForRestoring = this.checkComponentStateDataForRestore();
    const goBackFromWlans = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromWlans');
    const needRestoreState = hasLocalStorageDataForRestoring && goBackFromWlans;
    if (needRestoreState) {
      this.loadComponentStateDataForRestoreAndRestore();
      this.removeComponentStateDataForRestore();
    }
    if (!needRestoreState && hasLocalStorageDataForRestoring) {
      this.removeComponentStateDataForRestore();
    }

    if (!needRestoreState) {
      this.filters.location = this.userBaseLocation;
      // this.$store.commit('setCpesList', false);
      if (this.$store.state.inHeaderLocationSelectModeEnabled &&
        inHeaderLocationSelectedApplying) {
        let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
        let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
        if (!locationForFiltering && locationForFiltering === '') {
          locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
          this.locationInFiltersData = commonService.locationObjbyId(locationForFiltering)
          this.filters.location = locationForFiltering
        } else {
          this.filters.location = locationForFiltering
          this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        }

       // this.filters.location = locationForFiltering
        this.filters.with_childs = withChildsForFiltering;
      }

      this.getWlansRating();
      // this.$store.commit('setWlanStatId', null);
    }
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    },
    toFixed(val, precission) {
      if (val && precission) {
        return val.toFixed(precission);
      }
    }
  }
};
</script>

<style lang="scss">
    .input-group-for-location-select-in-statwlans--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;

  }

  .select-location-in-statwlans--filters .select__list-container {
    width: 400px;
  }
.cpes {
  display: flex;
}

.wlans-rating-list {
  width: 100%;
}

.wlan-stat {
  display: inline-block;
  width: 0;
  word-wrap: break-word;
}

.stat-visible .wlans-rating-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 25%;
}

.stat-visible .wlan-stat {
  transition: width 0.2s;
  width: 75%;
}

.btn {
  cursor: pointer;
}

.table-row-move {
  transition: all 0.5s;
}

.save-icon {
  position: absolute !important;
  top: 10px;
  right: 30px;
  z-index: 900;
}

.loader-backdrop-statwlans:after {
  position: absolute;
  top: 30px;
}
.switcher-with-childs-in-stat-wlans label.switch {
  margin-bottom: 0 !important;
}
</style>

<style scoped>
.disabled-when-date-range-limit-exceeded-error {
  cursor: not-allowed !important;
}
.with-child {
  position: relative;
  font-size: 0.7em;
  padding: 0;
  margin: 0em;
  border: 0;
  top: -1px;
}
.with-child-wrapper {
  /*margin-left: 0.3em;*/
}
.switcher-with-childs-in-stat-wlans label.switch {
  margin: 0 !important;
}
.switcher-with-childs-in-stat-wlans label {
  height: 27px !important;
  margin: 0px !important;
}

.switcher-with-childs-in-stat-wlans span.switch-handle,
.switcher-with-childs-in-stat-wlans span.switch-label {
  height: 27px !important;
}
.switcher-with-childs-in-stat-wlans-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  margin-left: 5px !important;
  height: 27px !important;
}
.switcher-with-childs-in-stat-wlans-caption {
  /*margin: 0px !important;*/
  margin-left: -12px !important;
  text-align: center;
}
</style>
